<template>
  <custom-dialog ref="dialog" title="Alterar lider" @submit="submit()">
    <span class="text-body-1">
      Atenção! Você está prestes a alterar a liderança do time.
    </span>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { changeLeader } from "@/services/league/teams.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      form: {
        team: "",
        player: "",
      },
    };
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        await changeLeader(this.form).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog(event) {
      this.form = event;
      await this.$refs.dialog.openDialog();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
