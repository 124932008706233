<template>
  <loader-view v-if="loading" />

  <section v-else class="py-12">
    <v-container class="py-0">
      <v-row class="mb-8">
        <v-col cols="12" sm="5" md="3" lg="2" xl="1">
          <div class="d-flex align-center justify-cente">
            <v-avatar class="border" color="accent" size="100%" tile>
              <v-img
                :src="team.logo || require('@/assets/league/empty-logo.png')"
                :aspect-ratio="4 / 4"
              />
            </v-avatar>
          </div>
        </v-col>

        <v-col>
          <div
            class="d-flex flex-column fill-height justify-center pl-0 pl-md-8"
          >
            <h1 class="text-h4 overtext--text">
              {{ team.name }} <span v-if="team.tag">[{{ team.tag }}]</span>
            </h1>

            <router-link
              class="text-h6 font-weight-regular primary--text text-decoration-none mb-4"
              :to="`/league/league-details/${team.league.id}`"
            >
              <span>
                {{ team.league.name }}
              </span>
            </router-link>
          </div>
        </v-col>
      </v-row>

      <section>
        <div
          class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
        >
          <header-view :title="'Jogadores da equipe'" class="mb-8 mb-md-0" />

          <section class="d-flex justify-end">
            <v-chip color="accent" class="subtext--text mr-2" label>
              Convite: {{ team.code }}
            </v-chip>

            <v-chip color="accent" class="subtext--text" label>
              Equipe: {{ _teamPlayers.length }} / {{ team.league.x }}
            </v-chip>
          </section>
        </div>

        <custom-table :headers="headers" :items="_teamPlayers">
          <template v-slot:[`item.player`]="{ item }">
            <v-list-item class="px-0">
              <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
                <v-img
                  :src="
                    item.avatar || require('@/assets/league/empty-logo.png')
                  "
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    class="overtext--text"
                    :to="`/league/player-details/${item.id}`"
                  >
                    {{ item.nickname }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
                <v-img
                  :src="
                    item.avatar || require('@/assets/league/empty-logo.png')
                  "
                />
              </v-list-item-avatar>
            </v-list-item>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="rounded-lg mr-1"
              :color="item.id === team.user.id ? 'amber' : 'overtext'"
              icon
              small
              @click="handleChangeLeader(item.id, item.id === team.user.id)"
            >
              <v-icon>
                {{
                  item.id === team.user.id ? "mdi-crown" : "mdi-crown-outline"
                }}
              </v-icon>
            </v-btn>

            <v-btn
              class="rounded-lg"
              icon
              small
              @click="handleKickPlayer(item.id)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </custom-table>
      </section>
    </v-container>

    <ChangeLeader ref="changeLeader" @success="getData()" />

    <KickPlayer ref="kickPlayer" @success="getData()" />
  </section>
</template>

<script>
import { getTeam } from "@/services/league/teams.js";
import ChangeLeader from "@/components/league/teams/ChangeLeader.vue";
import KickPlayer from "@/components/league/teams/KickPlayer.vue";

export default {
  data() {
    return {
      loading: true,
      team: null,
      headers: [
        {
          text: "Jogador",
          value: "player",
        },
        {
          value: "actions",
          width: "10%",
        },
      ],
    };
  },

  components: {
    ChangeLeader,
    KickPlayer,
  },

  beforeMount() {
    this.getData();
  },

  computed: {
    _teamPlayers() {
      if (this.team.team_players && this.team.team_players.length > 0)
        return this.team.team_players.map((e) => {
          return {
            ...e.user,
            ready: e.ready,
          };
        });
      else return [this.team.user];
    },
  },

  methods: {
    async getData() {
      try {
        await getTeam(this.$route.params.id).then((res) => {
          this.team = res;
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.$router.push({ path: "/league/teams" });
      }
    },

    handleChangeLeader(event, ignore) {
      if (ignore) return;

      const payload = {
        team: this.team.id,
        player: event,
      };

      this.$refs.changeLeader.handleDialog(payload);
    },

    handleKickPlayer(event) {
      const payload = {
        team: this.team.id,
        player: event,
      };

      this.$refs.kickPlayer.handleDialog(payload);
    },

    // copy do clipboard
    copy(value) {
      var textBox = document.getElementById(value);
      textBox.select();

      document.execCommand("copy");
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 1px white solid !important;
}
</style>
