import { request } from "./index";

export const getTeams = (data) =>
  request("get", "/teams", {
    params: data,
  });

export const getTeam = (data) => request("get", `/teams/${data}`);

export const changeLeader = (data) =>
  request("get", `/teams/change-leader`, data);

export const kickPlayer = (data) => request("get", `/teams/kick-player`, data);

export const addPlayer = (data) => request("get", `/teams/add-player`, data);
